import { DialogController }                from 'aurelia-dialog';
import { bindable, inject }                from 'aurelia-framework';
import { PLATFORM }                        from 'aurelia-pal';
import { BaseFormViewModel }               from 'base-form-view-model';
import { BulletinRevisionFormSchema }      from 'modules/management/specifications/bulletins/bulletin-revisions/form-schema';
import { BulletinRevisionFilesRepository } from 'modules/management/specifications/bulletins/bulletin-revisions/services/files-repository';
import { BulletinRevisionsRepository }     from 'modules/management/specifications/bulletins/bulletin-revisions/services/repository';
import { BulletinsRepository }             from 'modules/management/specifications/bulletins/services/repository';
import { AppContainer }                    from 'resources/services/app-container';

@inject(AppContainer, DialogController, BulletinsRepository, BulletinRevisionsRepository, BulletinRevisionFilesRepository, BulletinRevisionFormSchema)
export class CreateBulletinRevisionModal extends BaseFormViewModel {

    formId = 'management.specifications.bulletins.revisions.creation-form';

    @bindable alert = {};
    model           = {};
    schema          = {};
    create          = true;

    bulletinId;

    @bindable settings = {
        title:   'form.title.create-new-record',
        size:    'modal-full',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param bulletinsRepository
     * @param repository
     * @param bulletinRevisionFilesRepository
     * @param formSchema
     */
    constructor(appContainer, dialogController, bulletinsRepository, repository, bulletinRevisionFilesRepository, formSchema) {
        super(appContainer);

        this.dialogController                = dialogController;
        this.bulletinsRepository             = bulletinsRepository;
        this.repository                      = repository;
        this.bulletinRevisionFilesRepository = bulletinRevisionFilesRepository;
        this.formSchema                      = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/specifications/bulletins/bulletin-revisions/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletins.manage',
            'management.specifications.bulletins.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.bulletinId                 = params.bulletin.id;
        this.nextBulletinRevisionNumber = await this.fetchNextBulletinRevisionNumber();
        this.lastBulletinRevisionModel  = await this.fetchLastBulletinRevisionModel();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model(this.nextBulletinRevisionNumber, this.lastBulletinRevisionModel);
        this.model        = this.formSchema.model(this.nextBulletinRevisionNumber, this.lastBulletinRevisionModel);
    }

    /**
     * Fetches last bulletin revision number
     *
     * @return {Promise<*>}
     */
    async fetchNextBulletinRevisionNumber() {
        return await this.bulletinsRepository.nextBulletinRevisionNumber(this.bulletinId);
    }

    /**
     * Fetches last bulletin revision model
     *
     * @return {Promise<*>}
     */
    async fetchLastBulletinRevisionModel() {
        return await this.bulletinsRepository.lastBulletinRevisionModel(this.bulletinId);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.bulletinId, this.model)
            .then((response) => {
                this.handleResponse(response);
                this.initialModel.assign(this.model);

                if (response.status === true) {
                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }

}
