import { DialogController }         from 'aurelia-dialog';
import { bindable, inject }         from 'aurelia-framework';
import { BaseListViewModel }        from 'base-list-view-model';
import { RevisionFieldsRepository } from 'modules/management/specifications/bulletins/bulletin-revision-fields/services/repository';
import { AppContainer }             from 'resources/services/app-container';

@inject(AppContainer, RevisionFieldsRepository, DialogController)
export class ReorderFieldsModal extends BaseListViewModel {

    listingId = 'management-specifications-bulletin-revision-fields-listing';

    @bindable headerTitle    = 'listing.management.specifications.bulletin-revision-fields';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    @bindable alert = {};

    @bindable settings = {
        title:   'form.title.reorder-fields',
        size:    'modal-lg',
        buttons: false,
    };

    fullData = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletins.manage',
            'management.specifications.bulletins.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.bulletinRevisionId = params;

        await this.loadFullData();
        this.defineDatatable();
    }

    /**
     * Loads the full data set
     */
    async loadFullData() {
        const response = await this.repository.search(this.bulletinRevisionId, {});
        this.fullData  = response.data;
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.search(criteria),
                destroy:         null,
                destroySelected: false,
            },
            edit:            null,
            destroy:         null,
            destroySelected: false,
            options:         [],
            buttons:         [],
            selectable:      false,
            actions:         [
                {
                    icon:    'icon-circle-up2',
                    action:  (row, index) => this.moveUp(row, index),
                    tooltip: 'form.button.move-up',
                    visible: (row) => row.order !== 1,
                },
                {
                    icon:    'icon-circle-down2',
                    action:  (row, index) => this.moveDown(row, index),
                    tooltip: 'form.button.move-down',
                    visible: (row) => row.order !== this.fullData.length,
                },
            ],
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:      'order',
                    name:      'bulletin_revision_fields.order',
                    title:     'form.field.order',
                    orderable: false,
                },
                {
                    data:      'code',
                    name:      'bulletin_revision_fields.code',
                    title:     'form.field.code',
                    orderable: false,
                },
                {
                    data:      'description',
                    name:      'bulletin_field_translations.description',
                    title:     'form.field.field',
                    orderable: false,
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search(this.bulletinRevisionId, criteria);
    }

    /**
     * Cancels the dialog
     *
     * @return {*}
     */
    cancel() {
        return this.dialogController.cancel();
    }

    /**
     * Updates the order of the records
     */
    async updateOrder() {
        this.fullData.forEach((row, index) => {
            row.order = index + 1;
        });

        const response = await this.repository.updateOrder(this.bulletinRevisionId, this.fullData);

        if (response.status !== true) {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }
    }

    /**
     * Moves the record up on the list
     *
     * @param row
     * @param index
     */
    async moveUp(row, index) {
        const fullIndex = this.fullData.findIndex(item => item.id === row.id);
        if (fullIndex > 0) {
            let part = this.fullData.splice(fullIndex, 1);
            this.fullData.splice(fullIndex - 1, 0, part[0]);
            await this.updateOrder();
            this.datatable.instance.reload();
        }
    }

    /**
     * Moves the record down on the list
     *
     * @param row
     * @param index
     */
    async moveDown(row, index) {
        const fullIndex = this.fullData.findIndex(item => item.id === row.id);
        if (fullIndex < (this.fullData.length - 1)) {
            let part = this.fullData.splice(fullIndex, 1);
            this.fullData.splice(fullIndex + 1, 0, part[0]);
            await this.updateOrder();
            this.datatable.instance.reload();
        }
    }
}
