import { DialogController, DialogService } from 'aurelia-dialog';
import { bindable, inject }                from 'aurelia-framework';
import { PLATFORM }                        from 'aurelia-pal';
import { ListRevisionFields }              from 'modules/management/specifications/bulletins/bulletin-revision-fields/index';
import { AppContainer }                    from 'resources/services/app-container';
import { RevisionFieldsRepository }        from './services/repository';

@inject(AppContainer, RevisionFieldsRepository, DialogService, DialogController)
export class ListRevisionFieldsModal extends ListRevisionFields {

    @bindable settings = {
        title:   'form.title.fields',
        size:    'modal-xl',
        buttons: false,
    };

    constructor(appContainer, repository, dialogService, dialogController) {
        super(appContainer, repository, dialogService);

        this.dialogController = dialogController;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/specifications/bulletins/bulletin-revision-fields/index-modal.html');
    }

    /**
     * Cancels the dialog
     *
     * @return {*}
     */
    cancel() {
        return this.dialogController.cancel();
    }
}
